import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";

import routes from "Constants/Route.constants";
import { BoxInput, RadioInput, Divider, TextButton, Dropdown, IconButton } from "UI";
import { getItemFromLocalStorage, Loader, saveItemToLocalStorage } from "Utils";

import ChromeLogo from "Assets/icons/chrome.svg";
import WindowsLogo from "Assets/icons/windows.svg";
import VagonSuprise from "Assets/images/preinstall-info.png";

import CreateComputerAction from "Components/Dashboard/CreateComputer/Components/CreateComputerAction/CreateComputerAction.component";
import { LOCAL_STORAGE, REQUEST_STATUS } from "Constants/global.constants";

import ComputerSpace from "./ComputerSpace/ComputerSpace.component";
import SoftwareCard from "./SoftwareCard/SoftwareCard.component";
import SoftwareRequestCard from "./SoftwareCard/SoftwareRequestCard.component";

import "./CreateComputerPreInstall.styles.scss";

const BASE_SIZE = 30;
const OS_SIZE = 29;
const CHROME_SIZE = 1;

const ERROR_RESET_TIMEOUT = 4000;

const DISK_SIZES = [75, 125, 175, 275];

const TAGS = {
  digitalArt: "Digital Art",
  videoEditing: "Video & Motion",
  architecture: "Architecture & CAD",
  gameDesign: "Game Design",
  essentials: "Essentials",
  gaming: "Gaming",
  dataScience: "Data Science",
};

const DiskSelection = (props) => {
  const { diskSize, selectedSpace, setDiskSize, estimatedSize } = props;
  const monthlyIncrease = parseFloat((diskSize - 75) / 10);
  const label = (
    <>
      {diskSize} GB
      {monthlyIncrease > 0 && <span> + {monthlyIncrease} USD/montly</span>}
    </>
  );
  return (
    <RadioInput
      disabled={diskSize <= estimatedSize}
      checked={selectedSpace === diskSize}
      label={label}
      onChange={() => setDiskSize(diskSize)}
      centered
    />
  );
};

const CreateComputerPreInstall = (props) => {
  const {
    account,
    onComplete,
    workstationConfig,
    selectedSoftwareIds,
    preSelectedPackageId,
    validateSoftwares,
    getSoftwaresCTX,
    installSoftwaresCTX,
    setDiskSize,
    history,
    translate,
    subscriptions,
  } = props;

  const { estimatedSize, estimatedTime, installerSize } = installSoftwaresCTX;
  const { softwares, packages } = getSoftwaresCTX;
  const selectedSpace = workstationConfig.disk_size;

  const [selectedPackageId, setSelectedPackageId] = useState(preSelectedPackageId);
  const [softwaresMap, setSoftwaresMap] = useState(null);
  const [error, setError] = useState(false);
  const [showVagonAppInfo, setShowVagonAppInfo] = useState(
    getItemFromLocalStorage(LOCAL_STORAGE.showVagonAppInfo, true),
  );

  const [searchInput, setSearchInput] = useState("");
  const [selectedTag, setSelectedTag] = useState(null);

  const errorTimeoutRef = useRef(null);

  const selectedSoftwares = softwaresMap && _.map(selectedSoftwareIds, (id) => softwaresMap[id]);

  useEffect(() => {
    return () => {
      clearTimeout(errorTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (softwares) {
      const map = _.reduce(
        softwares,
        (obj, item) => ({
          ...obj,
          [item.id]: item,
        }),
        {},
      );
      setSoftwaresMap(map);
    }
  }, [softwares]);

  useEffect(() => {
    setError(null);
  }, [selectedSpace]);

  const onActionButtonClick = () => {
    if (account.isTeamAccount) {
      onComplete();
    } else {
      history.push(routes.createComputerSubscription);
    }
  };

  const handleSofwareClick = (software) => {
    const { id } = software;
    const indexOfId = _.findIndex(selectedSoftwares, (sw) => sw.id === id);

    if (indexOfId > -1) {
      _.remove(selectedSoftwares, (sw) => sw.id === id);
    }
    // Check if the remaining size is enough
    else if (selectedSpace - estimatedSize > software.attributes.size) {
      selectedSoftwares.push(software);
      setError(null);
    } else {
      setError(`s${software.id}`);
      clearTimeout(errorTimeoutRef.current);
      errorTimeoutRef.current = setTimeout(() => setError(null), ERROR_RESET_TIMEOUT);
    }

    const softwareIds = _.map(selectedSoftwares, (sw) => sw.id);
    validateSoftwares(softwareIds, selectedPackageId, selectedSpace);
  };

  const handlePackageClick = (clickedPackage) => {
    const packageSize = clickedPackage.attributes.size - BASE_SIZE;
    if (selectedPackageId === clickedPackage.id) {
      setSelectedPackageId(null);
      validateSoftwares(selectedSoftwareIds, null, selectedSpace);
    } else if (selectedSpace - estimatedSize > packageSize) {
      setSelectedPackageId(clickedPackage.id);
      validateSoftwares(selectedSoftwareIds, clickedPackage.id, selectedSpace);
      setError(null);
    } else {
      setError(`p${clickedPackage.id}`);
      clearTimeout(errorTimeoutRef.current);
      errorTimeoutRef.current = setTimeout(() => setError(null), ERROR_RESET_TIMEOUT);
    }
  };

  const handleShowVagonAppInfoClose = () => {
    saveItemToLocalStorage(LOCAL_STORAGE.showVagonAppInfo, false);
    setShowVagonAppInfo(false);
  };

  const onCloseButtonClick = () => {
    handleShowVagonAppInfoClose();
  };

  if (getSoftwaresCTX.status !== REQUEST_STATUS.SUCCESS) {
    return <Loader />;
  }

  return (
    <div className="preinstall-container">
      <div className="softwares-board">
        <div className="search-container mobile-only-hide">
          <div className="search-input">
            <BoxInput
              placeholder={translate("selectSoftware.content.searchPlaceholder")}
              iconName="search"
              color="purple-main"
              value={searchInput}
              onChange={(event) => {
                setSearchInput(event.target.value);
              }}
            />
          </div>
          <div className="search-tags">
            <TextButton
              text="All"
              selected={!selectedTag}
              onClick={() => {
                setSelectedTag(null);
              }}
            />
            {Object.values(TAGS).map((tag) => (
              <TextButton
                key={tag}
                text={tag}
                selected={selectedTag === tag}
                onClick={() => {
                  if (selectedTag === tag) setSelectedTag(null);
                  else setSelectedTag(tag);
                }}
              />
            ))}
          </div>
        </div>

        <h1 className="mobile-only">{translate("selectSoftware.content.softwaresHeader")}</h1>
        <div className="software-list-container">
          <div className="software-list">
            {showVagonAppInfo && (
              <div className="vagon-application-info">
                <div className="header">
                  <img src={VagonSuprise} alt="vagon-suprise" />
                </div>
                <div className="description">
                  <IconButton label="close" onClick={onCloseButtonClick} name="close" color="white" />
                  <p>{translate("selectSoftware.content.vagonInfo.description")}</p>
                </div>
              </div>
            )}
            {_.map(packages, (softwarePackage) => {
              if (searchInput && !softwarePackage.attributes.name.toLowerCase().includes(searchInput.toLowerCase()))
                return null;
              if (selectedTag && !softwarePackage.attributes.tags.includes(selectedTag)) return null;

              return (
                <SoftwareCard
                  key={softwarePackage.id}
                  img={softwarePackage.attributes.logo}
                  name={softwarePackage.attributes.name}
                  softwares={softwarePackage.attributes.softwares}
                  selected={selectedPackageId === softwarePackage.id}
                  disabled={selectedPackageId && selectedPackageId !== softwarePackage.id}
                  error={error === `p${softwarePackage.id}`}
                  onClick={() => {
                    handlePackageClick(softwarePackage);
                  }}
                />
              );
            })}
            {_.map(softwares, (software) => {
              if (searchInput && !software.attributes.name.toLowerCase().includes(searchInput.toLowerCase()))
                return null;
              if (selectedTag && !software.attributes.tags.includes(selectedTag)) return null;

              return (
                <SoftwareCard
                  key={software.id}
                  name={software.attributes.name}
                  img={software.attributes.logo}
                  version={software.attributes.version}
                  size={software}
                  selected={selectedSoftwareIds.includes(software.id)}
                  error={error === `s${software.id}`}
                  onClick={() => {
                    handleSofwareClick(software);
                  }}
                />
              );
            })}
            <SoftwareRequestCard translate={translate} />
          </div>
        </div>
        <div className="storage-selection-section">
          <div className="storage-selection mobile-only-hide">
            {!account.isTeamAccount && (
              <>
                <p>{translate("selectSoftware.content.selectStorage")}</p>
                {DISK_SIZES.map((diskSize) => (
                  <DiskSelection
                    key={diskSize}
                    diskSize={diskSize}
                    selectedSpace={selectedSpace}
                    setDiskSize={setDiskSize}
                    estimatedSize={estimatedSize}
                  />
                ))}
              </>
            )}
          </div>
          <ComputerSpace
            totalSpace={selectedSpace}
            baseSpace={BASE_SIZE}
            fullSpace={estimatedSize - installerSize}
            error={error && translate("selectSoftware.content.error.notEnoughSpace")}
          />
          {!account.isTeamAccount && (
            <>
              <div className="storage-selection mobile-only">
                <p>{translate("selectSoftware.content.selectStorage")}</p>
                <Dropdown
                  defaultSelected={selectedSpace}
                  options={DISK_SIZES}
                  mapFunction={(option) => {
                    if (option <= estimatedSize) return null;
                    return `${option} GB`;
                  }}
                  handleSelectedOptionChange={(option) => {
                    setDiskSize(option);
                  }}
                />
              </div>
            </>
          )}
          <div className="small-screen-only">
            <CreateComputerAction
              buttonText={
                account.isTeamAccount
                  ? translate("selectSoftware.action.teamMember")
                  : translate("selectSoftware.action.next")
              }
              onButtonClick={onActionButtonClick}
              disabled={subscriptions.createUserWorkstationAsSubscriptionCTX.status !== REQUEST_STATUS.NOT_DEFINED}
            />
          </div>
        </div>
      </div>
      <div className="selected-software-list mobile-only-hide">
        <h1>{translate("selectSoftware.content.selectedSoftwaresHeader")}</h1>
        <div className="selected-sotwares">
          <div className="software-row">
            <SoftwareCard img={WindowsLogo} name="OS" size={OS_SIZE} small hideText />
            <SoftwareCard img={ChromeLogo} name="Chrome" size={CHROME_SIZE} small hideText />
            {selectedSoftwares?.map((software) => {
              return (
                <SoftwareCard
                  key={software?.id}
                  name={software?.attributes?.name}
                  size={software?.attributes?.size}
                  img={software?.attributes?.logo}
                  small
                  hideText
                />
              );
            })}
          </div>
          {selectedPackageId && (
            <div className="selected-package">
              {/* <Icon name="plus" /> */}
              {_.map(packages, (packet) => {
                if (selectedPackageId !== packet.id) return null;

                return (
                  <SoftwareCard
                    key={packet.id}
                    images={_.map(packet.attributes.softwares, (sw) => sw.attributes.logo)}
                    name={packet.attributes.name}
                    size={packet.attributes.size - BASE_SIZE}
                    hideText
                    small
                    wide
                  />
                );
              })}
            </div>
          )}
        </div>
        <CreateComputerAction
          buttonText={
            account.isTeamAccount
              ? translate("selectSoftware.action.teamMember")
              : translate("selectSoftware.action.next")
          }
          onButtonClick={onActionButtonClick}
          actionContent={
            <>
              <div className="installation">
                {estimatedTime > 1 ? (
                  <p>{translate("selectSoftware.content.installationTime.estimated", { estimatedTime })}</p>
                ) : (
                  <p>{translate("selectSoftware.content.installationTime.ready")}</p>
                )}
                <TextButton
                  text={account.isTeamAccount ? "Skip & Create" : "Skip this step"}
                  onClick={() => {
                    if (account.isTeamAccount) {
                      onComplete();
                    } else {
                      history.push(routes.createComputerSubscription);
                      validateSoftwares([], null, selectedSpace);
                    }
                  }}
                />
              </div>
              <Divider color="white-fill" withLogo />
            </>
          }
          disabled={subscriptions.createUserWorkstationAsSubscriptionCTX.status !== REQUEST_STATUS.NOT_DEFINED}
        />
      </div>
    </div>
  );
};

export default CreateComputerPreInstall;
